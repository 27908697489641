import React, { useRef, useEffect, useState } from 'react';
import { getAllowedAttributes } from 'helper/form/fields';
import { useDetectOutsideClick } from 'helper/useDetectOutsideClick';
import Button from 'components/ui/Button';

const ProductCategory = (props) => {
    const allowedAttributes = [
        'name',
        'value',
        'disabled',
        ...props.allowedAttributes
    ];
    const attributes = getAllowedAttributes(props, allowedAttributes);
    const buttonRef = useRef(null);
    const menuRef = useRef(null);
    const [open, setOpen] = useDetectOutsideClick(menuRef, false);
    const [value, setValue] = useState(props.value.length > 2 ? props.value.split(',').map((cat) => parseInt(cat)) : []);
    const dropdownToggle = (event) => {
        event.preventDefault();
        setOpen(!open);
    }
    const getSelectedTitle = () => {
        let title = 'Any';
        if (value.length === 1) {
            if (typeof props.options[value[0]] !== 'undefined') {
                title = props.options[value[0]].title
            } else {
                Object.entries(props.options).forEach(([index, option]) => {
                    if (Object.entries(option.children).length) {
                        Object.entries(option.children).forEach(([childIndex, childOption]) => {
                            if (childOption.id === value[0]) {
                                title = childOption.title;
                            }
                        });
                    }
                });
            }
        } else if (value.length > 1) {
            title = value.length + ' categories';
        }
        return title;
    }
    const selectParentOption = (event) => {
        selectOption(event, true);
    }
    const selectOption = (event, parent = false) => {
        event.preventDefault();
        const targetValue = parseInt(event.currentTarget.dataset.value);
        const newValue = [...value];
        const check = value.includes(targetValue);
        if (check) {
            newValue.splice(newValue.indexOf(targetValue), 1);
        } else {
            newValue.push(targetValue);
        }
        if (parent) {
            if (Object.entries(props.options[targetValue].children).length) {
                Object.entries(props.options[targetValue].children).forEach(([childIndex, childOption]) => {
                    if (!check && !newValue.includes(childOption.id)) {
                        newValue.push(childOption.id);
                    } else if (check && newValue.includes(childOption.id)) {
                        newValue.splice(newValue.indexOf(childOption.id), 1);
                    }
                });
            }
        }
        setValue(newValue.filter(item => !isNaN(item)));
    }
    const clearFilter = (event) => {
        setValue([]);
        setOpen(false)
    }
    useEffect(() => {
        const setKeydown = (event) => {
            const dropdownFocused = buttonRef.current && document.activeElement === buttonRef.current;
            if (!dropdownFocused && ['Enter', 'NumpadEnter'].includes(event.code)) {
                event.preventDefault();
            }
            if (open) {
                if (event.code === 'Escape') {
                    setOpen(false);
                }
            }
        }
        document.addEventListener('keydown', setKeydown, false);
        return () => {
            document.removeEventListener('keydown', setKeydown, false);
        };
    }, [open, setOpen, props]);
    useEffect(() => {
        if (!open) {
            if (JSON.stringify(value) !== props.value) {
                const changeEvent = { target: { name: props.name, value: JSON.stringify(value) } };
                props.inputChangeHandler(changeEvent);
            }
        }
    }, [open, value]);
    let className = 'form__field-control form__field-control--select' + (open ? ' form__field-control--category-open' : '');
    return (
        <div className="form__field-category-wrapper">
            <button ref={ buttonRef } type="button" className={ className } { ...attributes } onClick={ dropdownToggle }>{ getSelectedTitle() }</button>
            <div ref={ menuRef } className={ 'form__field-category-options' + (open ? ' form__field-category-options--open' : '') }>
                <div>
                    <ul>
                        {
                            Object.entries(props.options).map(([id, option]) => {
                                return <li className={ 'form__field-category-options-list-item' + (value.includes(option.id) ? ' form__field-category-options-list-item--selected' : '') } key={ option.id }>
                                    <button type="button" data-value={ option.id } tabIndex={ open ? '' : '-1' } onClick={ selectParentOption }>{ option.title }</button>
                                    { option.children && Object.entries(option.children).length > 0 &&
                                        <ul>
                                            { Object.entries(option.children).map(([subId, subOption]) => {
                                                return <li className={ 'form__field-category-options-list-item' + (value.includes(subOption.id) ? ' form__field-category-options-list-item--selected' : '') } key={ option.id + '_' + subOption.id }>
                                                    <button type="button" data-value={ subOption.id } tabIndex={ open ? '' : '-1' } onClick={ selectOption }>{ subOption.title }</button>
                                                </li>
                                            })
                                            }
                                        </ul>
                                    }
                                </li>
                            })
                        }
                    </ul>
                    <div className="form__field-category-options-footer"><Button size="small" onClick={ () => setOpen(false) }>Filter categories</Button><Button size="small" outline onClick={ clearFilter }>Clear filters</Button></div>
                </div>
            </div>
        </div>
    );
};

export default ProductCategory;
